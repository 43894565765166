import React, { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReceiptView } from '../../../domain/Receipt/ReceiptView';
import { KYCControlInterface, KYCDocumentInterface } from '../../../domain/Folder/KYCControl';
import Receipt from '../validation/Receipt';
import IconFactory from '../svg/IconFactory';
import Spinner from '../svg/Spinner';
import ErrorModal from '../modal/ErrorModal';
import PreviewDocumentModal, { IPreviewFile } from '../modal/PreviewModal';
import GetKYCReportUseCase from '../../../useCase/KYC/GetKYCReportUseCase';
import KYCGateway from '../../../gateway/KYC/KYCGateway';
import { PreviewFile } from '../modal/PreviewFile';
import { ErrorResponse } from '../../../domain/Api/Type';


interface Props {
  dossierId: string
  receipts: { [key: string]: ReceiptView[] };
  kyc: KYCControlInterface|null|undefined
  scope: string
}

const ReceiptTab: React.FC<Props> = ({ dossierId, receipts, kyc, scope }) => {
  const { t } = useTranslation();

  const [previewReport, setPreviewReport] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string|null>(null)


  const getKYCReportCaption = previewReport ? t('button.download-in-progress') : t('button.download-report')
  const downloadKycWidget = (scope: string) => {
    return ('undefined' !== typeof kyc) ? (
      <div className="button-preview button-preview--reverse button-preview-download u-mbl"
        onClick={() => setPreviewReport(true)}
      >
        <IconFactory type="eye"/>
        <span className={`u-prs u-pxs`}>{previewReport ? t('button.{{country}}') : t('button.download-report')}</span>
      </div>
    ) : ( 
      (null === receipts && null === kyc) ? <Spinner /> : (<span className="u-txt-italic u-myl">{t('kyc.report_not_available')}</span>)
    )
  }

  const findKYCDocumentResult = (receipt: ReceiptView): KYCDocumentInterface|null => {
    if(!kyc) {
      return null;
    }

    const doc = kyc.documents?.filter((item) => { return item.receiptId === receipt.id || item.receiptId === receipt.receiptTypeId})
    if(doc?.length>0) {
      return doc[0];
    }
    
    return null;
  }

  const fetchReportFile = (id: string): Promise<IPreviewFile|null> => {
    const kycUseCase = new GetKYCReportUseCase(new KYCGateway())
    return kycUseCase.handle(dossierId, scope).then((response) => {
      if (response) {
        return new PreviewFile(
          response.resource,
          response.title,
          response.mimeType
        )
      }
      return null
    })
    .catch((errors: ErrorResponse) => {
      if (errors.errors?.length > 0) {
        setErrorMessage(errors.errors[0].message);
      } else {
        setErrorMessage(t(`kyc.unexpected_error`));
      }
      return null
    })
  }

  return (
    <>
      { errorMessage && <ErrorModal message={errorMessage} onClose={() => setErrorMessage(null)} /> }
      {previewReport && <PreviewDocumentModal id={`${dossierId}-kyc-np`} download={fetchReportFile} hide={() => setPreviewReport(false)} hideTitle={true}/>}

      <div className="flex-container">
        <div className={`u-mbm u-mxs`}>{downloadKycWidget}</div>
        {Object.keys(receipts).map((type, index) => (
          <div key={type} className="col-md-12">
            <h4 className={`${index > 0 ? 'u-mtm' : ''}`}>{type}</h4>
            {receipts[type].map((receipt) => (
              <Receipt key={receipt.id} receipt={receipt} kyc={kyc} document={findKYCDocumentResult(receipt)}/>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default ReceiptTab;
